.dropArea {
  border-radius: 20px;
  padding: 20px;
  -webkit-transition: border 0.1s;
  -moz-transition: border 0.1s;
  -o-transition: border 0.1s;
  transition: border 0.1s;
}

.dropArea.dashed {
  border: 2px dashed #ccc;
}

.dropArea.highlight {
  border-color: #000;
}

.form {
  margin-bottom: 10px;
}

.gallery {
  margin-bottom: 16px;
}

.galleryItem {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  color: #000;
  word-break: break-all;
}

.galleryItemFileName {
  white-space: nowrap;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
  padding-right: 4px;
}

.galleryItem:hover {
  cursor: default;
  color: #000;
  text-decoration: none;
}

.galleryItem.hasButDownload:hover {
  background-color: rgba(240, 240, 240, 1);
  cursor: pointer;
}

.input {
  display: none;
}

.butDownload {
  margin-right: 5px;
}

.progressBar {
  height: 20px;
  margin-top: 8px;
  display: block;
  width: 100%;
}

.iconPlus path {
  stroke: #007bff;
}

.wrapperForFile {
  cursor: pointer;
  height: 100%;
  padding: 0;
}

.formForFile {
  height: 100%;
  padding: 0;
}

.formForFile label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
