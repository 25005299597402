.alert-enter {
  opacity: 0;
}
.alert-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
