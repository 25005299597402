.container {
  padding: 10px;
  width: 100%;
  z-index: 1000;
  top: 0;
  display: block;
}

.hidden {
  display: none;
}
