body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page-content {
  background: #fff;
}

@supports (min-height: 100svh) {
  .page {
    min-height: 100svh;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
}

/* FOR AuthPage */

@media screen and (max-width: 426px) {
  body {
    background-color: #fff;
  }
}

#SRLLightbox {
  z-index: 2000;
}

.list-group {
  border-radius: 0;
}

/* Данные стили нужны для карусели новостей, не нашёл как быстро изменить стили в самом компоненте */
.carousel-indicators {
  margin-bottom: 0;
}
.carousel-control-prev {
  width: 10%;
}
.carousel-control-next {
  width: 10%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
