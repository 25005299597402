.icon {
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s;
}

.icon * {
  stroke: #007bff;
}

.icon:hover * {
  stroke: #0056b3;
}

.popup {
  position: absolute;
  top: -4px;
  left: 36px;
  background-color: #f5f5f5;
  z-index: 100;
  padding: 4px;
  width: 250px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s;
}
.icon:hover .popup {
  visibility: visible;
  opacity: 100;
}

@media screen and (max-width: 768px) {
  .icon {
    display: none;
  }
}
